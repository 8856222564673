export default class ServiceWorkerRegistration extends EventTarget {
    #options;

    #EVENTS = new Set([
        'onServiceWorkerNotSupported',
        'onBeforeServiceWorkerRegistration',
        'onServiceWorkerRegistered',
        'onBeforeServiceWorkerUpdating',
        'onServiceWorkerUpdated',
        'onServiceWorkerUpdateFound',
        'onServiceWorkerWaitingToActivate',
        'onServiceWorkerActivated',
        'onServiceWorkerFailedToInstall',
        'onServiceWorkerFailedToUpdate',
        'onServiceWorkerUnregistered',
        'onServiceWorkerFailedToUnregister'
    ]);

    get serviceWorkerSuported() {
        return 'serviceWorker' in window.navigator;
    }

    get serviceWorkerNotSupported() {
        return !this.serviceWorkerSuported;
    }

    get serviceWorkerContainer() {
        if (this.serviceWorkerNotSupported) {
            throw new Error('Service Worker not supported');
        }

        return window.navigator.serviceWorker;
    }

    get registeredServiceWorker() {
        return new Promise(function(resolve, reject) {
            if (this.serviceWorkerNotSupported) {
                throw new Error('Service Worker not supported');
            }

            this.serviceWorkerContainer.getRegistration(this.#serviceWorkerScope)
                .then((result) => resolve(result))
                .catch((error) => reject(error));
        }.bind(this));
    }

    get #serviceWorkerId() {
        return document.querySelector('meta[name=o365-service-worker-id]').content;
    }

    get #serviceWorkerUrl() {
        return `/service-worker/${this.#serviceWorkerId}`;
    }

    get #serviceWorkerScope() {
        const scope = self.location.pathname;

        if (typeof scope !== 'string' || scope.split('/').filter((pathPart) => pathPart.length > 0).length === 0) {
            throw new Error('Failed to retrieve a valid scope');
        }

        return scope;
    }

    constructor(options = {}) {
        super();

        this.#options = options;
    }

    async initialize() {
        return this.registerServiceWorker();
    }

    async registerServiceWorker() {
        try {
            if (this.serviceWorkerNotSupported) {
                super.dispatchEvent(new CustomEvent('onServiceWorkerNotSupported'));
                return;
            }

            const serviceWorkerUrl = this.#serviceWorkerUrl,
                serviceWorkerScope = this.#serviceWorkerScope;

            const scopeAlreadyRegisterdResult = await this.#scopeAlreadyRegistered(serviceWorkerScope);

            var serviceWorkerRegistration;

            if (scopeAlreadyRegisterdResult) {
                serviceWorkerRegistration = await this.serviceWorkerContainer.getRegistration();
            } else {
                super.dispatchEvent(new CustomEvent('onBeforeServiceWorkerRegistration'));

                serviceWorkerRegistration = await this.serviceWorkerContainer.register(serviceWorkerUrl, {
                    scope: serviceWorkerScope,
                    type: this.#options.type ?? 'classic',
                    updateViaCache: this.#options.updateViaCache ?? 'all'
                });
                
                super.dispatchEvent(new CustomEvent('onServiceWorkerRegistered', {
                    serviceWorkerRegistration: serviceWorkerRegistration
                }));
            }

            serviceWorkerRegistration.addEventListener('updatefound', () => {
                super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateFound'));
            });

            return serviceWorkerRegistration;
        } catch (error) {
            super.dispatchEvent(new CustomEvent('onServiceWorkerFailedToInstall', {
                error: error
            }));
        }
    }

    async updateServiceWorker() {
        try {
            var serviceWorkerRegistration = await this.serviceWorkerContainer.getRegistration();

            if (serviceWorkerRegistration === undefined) {
                serviceWorkerRegistration = await this.registerServiceWorker();
            } else {
                const serviceWorkerRegistrationUpdate = await serviceWorkerRegistration.update();

                super.dispatchEvent(new CustomEvent('onServiceWorkerUpdated', {
                    serviceWorkerRegistration: serviceWorkerRegistrationUpdate
                }));
            }

            serviceWorkerRegistrationUpdate.addEventListener('updatefound', () => {
                super.dispatchEvent(new CustomEvent('onServiceWorkerUpdateFound'));
            });
        } catch (error) {
            super.dispatchEvent(new CustomEvent('onServiceWorkerFailedToUpdate', {
                error: error
            }));
        }
    }

    async unregisterServiceWorker() {
        try {
            const serviceWorkerRegistration = await this.serviceWorkerContainer.getRegistration();

            await serviceWorkerRegistration?.unregister();

            super.dispatchEvent(new CustomEvent('onServiceWorkerUnregistered'));
        } catch (error) {
            super.dispatchEvent(new CustomEvent('onServiceWorkerFailedToUnregister', {
                error: error
            }));
        }
    }

    async #scopeAlreadyRegistered(providedScope) {
        const serviceWorkerRegistrations = await this.serviceWorkerContainer.getRegistrations();

        for (const serviceWorkerRegistration of serviceWorkerRegistrations) {
            const registeredScopeUrl = serviceWorkerRegistration.scope,
                registeredScopeUri = new URL(registeredScopeUrl),
                registeredScope = registeredScopeUri.pathname;

            if (registeredScope === providedScope) {
                return true;
            } else if (registeredScope.includes(providedScope)) {
                throw new Error('Invalid scope. Active service worker registred in a parent app');
            } else if (providedScope.includes(registeredScope)) {
                throw new Error('Invalid scope. Active service worker registered in a child app');
            }
        }

        return false;
    }

    addEventListener(event, callback) {
        if (this.#EVENTS.has(event) === false) {
            throw Error(`Failed to register event listener. Event '${event}' does not exist on ServiceWorkerRegistration`);
        }

        super.addEventListener(event, callback);
    }

    removeEventListener(event, callback) {
        if (this.#EVENTS.has(event) === false) {
            throw Error(`Failed to remove event listener. Event '${event}' does not exist on ServiceWorkerRegistration`);
        }

        super.removeEventListener(event, callback);
    }
}
